<template>
  <el-menu-item ref="menuItem"
                v-if="!hasChild(item.menuInfoEntity)"
                :route="{ path: item.menuUrl }"
                :index="item.menuId">
    <img v-show='item.menuIcon'
         style="width:30px;height:30px"
         :src="item.menuIcon?require('@/assets/layout/'+item.menuIcon+'.png'):''"
         alt="">
    <span style="padding-left:10px">{{ item.menuName }}</span>
  </el-menu-item>

  <el-submenu v-else
              ref="submenu"
              :index="item.menuId"
              popper-append-to-body>
    <template slot="title">
      <img v-show='item.menuIcon'
           style="width:30px;height:30px"
           :src="item.menuIcon?require('@/assets/layout/'+item.menuIcon+'.png'):''"
           alt="">
      <span style="margin-left:10px">{{ item.menuName }}</span>
    </template>
    <side-nav-item v-for="child in item.menuInfoEntity"
                   ref="navItem"
                   :key="child.path"
                   :is-nest="true"
                   :item="child"
                   :route-name="item.menuUrl" />
  </el-submenu>
</template>

<script>
export default {
  name: 'SideNavItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    menuUrl: {
      type: String,
      default: ''
    }
  },
  methods: {
    // 获取子系统下第一个可折叠菜单的menuid
    getFirstNav (callback) {
      // 判断模块是否有子模块
      this.$nextTick(() => {
        if (!this.hasChild(this.item.menuInfoEntity)) {
          // 没有子模块时 返回两个值（这个组件上一个组件-也就是第一个子模块可折叠的navbar的menuId，当前组件navbar也就是子模块第一个页面的menuId）
          callback(this.$refs.menuItem.$parent.item.parentId, this.$refs.menuItem.index)
        } else {
          // 有子模块时 继续调用该函数
          this.$refs.navItem[0].getFirstNav(callback)
        }
      })
    },

    hasChild (itemChildren) {
      if (itemChildren !== undefined && itemChildren.length !== 0) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
</style>
