<!--
 * @Descripttion: 
 * @version: 
 * @Author: 郭云展
 * @Date: 2021-10-08 10:32:16
 * @LastEditors: zhoucheng
 * @LastEditTime: 2022-05-31 17:20:19
-->
<template>
  <el-scrollbar>
    <el-menu ref="menu"
             @select="handleSelect"
             :collapse-transition="false"
             :collapse="collapse"
             :default-active="activeMenu"
             :unique-opened="false"
             mode="vertical"
             router>
      <side-nav-item v-for="route in routes"
                     :ref="route.menuId"
                     :item="route"
                     :key="route.menuId"
                     :route-name="route.menuUrl"></side-nav-item>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SideNavItem from './SideNavItem'
export default {
  name: 'index',
  props: {
    collapse: {
      type: Boolean,
      required: true,
      defaults: false
    },
    routes: {
      type: Array,
      required: true
    }
  },
  components: {
    SideNavItem
  },
  computed: {
    ...mapGetters([
      'activeMenu', 'theme'
    ]),
    ...mapState({
      theme: (state) => state.app.theme,
    })
  },
  data () {
    return {
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    setFirstNav (menuId) {
      this.$nextTick(() => {
        this.$refs[menuId][0].getFirstNav((navId, pageId) => {
          try {
            // 设置 第一个可展开的nav为展开，第一个页面为激活状态
            this.$refs.menu.activeIndex = pageId
            this.$refs.menu.open(navId)
          } catch (e) {
            // 当子系统第一个模块不能展开时会报错，不影响程序运行
            console.log(e);
          }
        })
      })
    },
    handleSelect (index) {
      this.$store.dispatch('app/activeMenu', { activeMenu: index })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-menu {
  border-right: none;
  text-align: left;
}
</style>
