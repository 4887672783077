<!--
* @Description: layout 的 index
 * @Author:zhoucheng
 * @Date: 2021-03-09 18:52:57
 * @LastEditTime: 2022-07-06 11:19:22
 * @LastEditors: zhoucheng
-->
<template>
  <el-row class="dt-layout">
    <!-- 头部 -->
    <div class="dt-layout-header">
      <div class="header-left">
        <img src="@/assets/layout/logo.png"
             @click="handleClickLeft">
      </div>
      <div class="header-right">
        <div class="header-right-title">您好:</div>
        <div class="header-right-text">
          <el-select :value="sysUser"
                     @change="handleClickHeadRight">
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!-- 内容展示主体部分 -->
    <el-row class="dt-layout-wrapper">
      <!-- 侧边栏 -->
      <el-col class="dt-layout-aside"
              :class="classObj">
        <div v-if="sidebar.opened"
             class="siderSelect">
          <el-select v-model="selectedValue"
                     @change="selectedChange">
            <el-option v-for="item in managementList"
                       :key="item.menuId"
                       :label="item.menuName"
                       :value="item.menuId">
            </el-option>
          </el-select>
        </div>
        <el-scrollbar style="overflow-y:auto;height:100%">
          <div style="height:92%">
            <dt-side-nav ref="sideNav"
                         :routes="routes"
                         :collapse="collapse"></dt-side-nav>
          </div>
        </el-scrollbar>
      </el-col>
      <!-- 内容部分 -->
      <el-col :class="mainObj"
              class="dt-layout-main">
        <el-scrollbar>
          <router-view></router-view>
        </el-scrollbar>
      </el-col>
      <!-- 异常离场消息推送 -->
      <el-col v-show="unusualDialogVisible"
              class="unusualClass"
              style="">
        <div v-for="(item,index) in unusualList.slice(pageSize * (pageNum - 1), pageSize * pageNum)"
             :key="index">
          <div class="unusualClass_parkName">
            <div class="unusualClass_parkName_charact">{{item.parkName}}
            </div>
            <el-button class="unusualClass_close"
                       @click="close">X</el-button>
          </div>
          <div v-if="String(item.plateColor)==='黄色'"
               class="unusualClass_plateColor_yellow">
            <div class="unusualClass_plateNumber_two">{{item.plateNumber}}</div>
          </div>
          <div v-if="String(item.plateColor)==='蓝色'"
               class="unusualClass_plateColor_blue">
            <div class="unusualClass_plateNumber_two">{{item.plateNumber}}</div>
          </div>
          <div v-if="String(item.plateColor)==='绿色'"
               class="unusualClass_plateColor_green">
            <div class="unusualClass_plateNumber_two">{{item.plateNumber}}</div>
          </div>
          <div class="unusualClass_leaveTime">{{item.leaveTime}}</div>
        </div>
        <div class="page">
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :page-size="pageSize"
                         :current-page="pageNum"
                         :pager-count="4"
                         background
                         layout="prev, pager, next, jumper"
                         :total="unusualList.length">
          </el-pagination>
        </div>
      </el-col>
      <!-- 修改密码弹出框 -->
      <el-dialog title="修改密码"
                 :visible.sync="updateDialogVisible"
                 :close-on-click-modal="false"
                 :show-close="cancelUpdatePwd"
                 append-to-body>
        <el-form label-width="110px"
                 :model="editFormList"
                 ref="editFormList"
                 :rules="rules"
                 clearValidate
                 :inline="true">
          <el-row justify="space-around"
                  style="margin-bottom:20px;text-align: center; color: red;">
            <el-col v-show="changePwdProup">
              <h2>#您长时间未修改密码，为了账号的安全，请修改密码#</h2>
            </el-col>
          </el-row>
          <el-row justify="space-around"
                  style="margin-bottom:20px;">
            <el-col :span="6">
              <el-form-item>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="请输入新密码"
                            prop="newPwd">
                <el-input class="dt-form-width"
                          type="password"
                          show-password
                          v-model="editFormList.newPwd"
                          placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
            </el-col>
          </el-row>
          <el-row justify="space-around"
                  style="margin-bottom:20px;">
            <el-col :span="6">
              <el-form-item>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="请确认新密码"
                            prop="affirmNewPwd">
                <el-input class="dt-form-width"
                          type="password"
                          show-password
                          v-model="editFormList.affirmNewPwd"
                          placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row type="flex"
                justify="end">
          <el-col :span="12"
                  class="dt-button-box">
            <el-button type="primary"
                       class="popBtn"
                       @click="updataPwd('editFormList')">确 定</el-button>
            <el-button type="info"
                       class="popBtn"
                       v-show="cancelUpdatePwd"
                       @click="updateDialogVisible = false">取 消</el-button>
          </el-col>
        </el-row>
      </el-dialog>
    </el-row>

  </el-row>
</template>

<script>
import DtSideNav from "./components/SideNav";
import { mapGetters, mapState } from "vuex";

import { ref } from 'vue'
// npm install webstomp-client
import webStomp from 'webstomp-client';
// npm install sockjs-client
import SockJS from 'sockjs-client'
import md5 from 'js-md5'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    DtSideNav,
  },
  data () {
    return {
      editFormList: {
      },
      cancelUpdatePwd: true,//长时间未登强制更改密码 不能取消
      changePwdProup: false,//长时间未更改密码弹窗提醒
      updateDialogVisible: false,//修改密码弹窗
      unusualDialogVisible: false, //异常消息推送弹窗
      msg: ref(''),
      text: ref(''),
      exceptionLeave: "/exceptionLeave",
      stompClient: null,
      unusualList: [],
      unusual: null,

      pageNum: 1,
      pageSize: 1,
      total: 0,

      collapse: false,
      managementList: [], //获取侧边栏
      selectedValue: 0,
      // 路由菜单数据
      routes: [],
      sysUser: "",
      updateTime: "",
      // testList: [
      //   {
      //     "parkName": "花卉路一号封闭停车场",
      //     "plateColor": "蓝色",
      //     "plateNumber": "苏A66889",
      //     "leaveTime": "2023-04-03 14:23:22"
      //   },
      // ],
      options: [
        {
          value: "退出登录",
          label: "退出登录",
        },
        {
          value: "返回上一级",
          label: "返回上一级",
        },
        {
          value: "换肤",
          label: "换肤",
        },
        {
          value: "修改密码",
          label: "修改密码",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["refreshPage"]),
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      isNotRefresh: (state) => state.app.isNotRefresh,
      theme: (state) => state.app.theme,
    }),
    classObj () {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
      };
    },
    mainObj () {
      return {
        withSidebar: this.sidebar.opened,
        withoutSidebar: !this.sidebar.opened,
      };
    },
  },
  watch: {},
  created () { },
  mounted () {
    this.sysUser = localStorage.getItem('userName')
    this.updateTime = localStorage.getItem('updateTime')
    this.userCode = localStorage.getItem('userCode')
    this.querySiderListByUser();
    window.document.documentElement.setAttribute("data-theme", this.theme);
    this.socketConnect();
    this.getDateUpdatePwd(this.updateTime);
    this.getQueryButtonListByUser();
  },
  methods: {
    //关闭
    close () {
      this.unusualDialogVisible = false;
    },
    handleCurrentChange (val) {
      this.pageNum = val
    },
    // 侧边栏按照用户查询
    querySiderListByUser () {
      this.$systemMenuManagement.queryListByUser({}).then((res) => {
        // 获取侧边栏下拉
        this.managementList = res.resultEntity;
        // 默认显示第一个子系统
        this.selectedValue =
          localStorage.getItem("subSystem") || this.managementList[0].menuId;
        this.routes = this.managementList[0].menuInfoEntity;
        // navbar数据请求完成后设置默认选中的 navbar
        this.selectedChange(localStorage.getItem("subSystem"));
      });
    },
    //获取按钮权限
    getQueryButtonListByUser () {
      this.$systemMenuManagement.queryButtonListByUser().then((res) => {
        localStorage.setItem("buttonListByUser", JSON.stringify(res.resultEntity))
        console.log(JSON.stringify(res.resultEntity));
      })
    },
    // 子系统发送切换
    selectedChange (menuId) {
      localStorage.setItem("subSystem", menuId);
      for (let item of this.managementList) {
        if (item.menuId === menuId) {
          // 子系统切换后给routes赋对应的值
          this.routes = item.menuInfoEntity;
          // 判断是不是没有刷新
          // 如果没有刷新页面 则 跳到第一个页面
          if (this.isNotRefresh) {
            this.setFirstPage(item);
          } else {
            // 否则将刷新状态设为没有刷新 且 不跳到第一个页面
            this.$store.dispatch("app/refreshPage", { refreshPage: true });
          }
        }
      }
    },
    // 设置第一个页面
    setFirstPage (item) {

      // 设置 第一个可展开的nav为展开，第一个页面为激活状态
      this.$nextTick(() => {
        this.$refs.sideNav.setFirstNav(item.menuInfoEntity[0].menuId);
      });
      // 获取第一个页面的路由
      this.getFistPage(item.menuInfoEntity, (fistPage) => {
        this.$router.replace({ path: fistPage });
      });
    },
    // 获取子系统第一个页面
    getFistPage (menuInfoEntity, callback) {
      if (menuInfoEntity[0].menuInfoEntity) {
        this.getFistPage(menuInfoEntity[0].menuInfoEntity, callback);
      } else {
        callback(menuInfoEntity[0].menuUrl);
      }
    },
    // 点击左侧图标
    handleClickLeft () {
      this.collapse = !this.collapse
      this.$store.dispatch("app/toggleSideBar");
    },
    handleClickHeadRight (value) {
      if (value === "退出登录") {
        this.$router.push("/login");
        this.$login.loginOut({}).then(() => {
          localStorage.removeItem("userToken")
          localStorage.removeItem("userName")
        })
      } else if (value === "返回上一级") {
        this.$router.push("/homeSlide");
      } else if (value == "换肤") {
        this.setTheme();
      } else if (value == "修改密码") {
        this.updatePassword();
      }
    },
    setTheme () {
      this.$store.dispatch("app/switchTheme");
    },
    //点击修改密码
    updatePassword () {
      if (this.userCode === 'sysAdmin') {
        this.$message.error('sysAdmin不可以修改密码');
      } else {
        this.editFormList = {},
          this.updateDialogVisible = true
      }
    },
    //确认修改
    updataPwd (info) {
      console.log(info);
      this.$confirm('确认修改?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        if (this.editFormList.newPwd !== this.editFormList.affirmNewPwd) {
          this.$message.error('两次输入的密码不一致');
        } else if (this.editFormList.newPwd === undefined || this.editFormList.affirmNewPwd === undefined) {
          this.$message.error('输入框不能为空');
        } else {
          let test = {
            passWord: md5(this.editFormList.affirmNewPwd)
          };
          this.$login.updatePassWord(test).then(() => {
            this.$message({ type: "success", message: "修改成功,请重新登录" });
            console.log(test);
            this.updateDialogVisible = false
            this.$router.push("/login");
          })
        }
      })

    },
    socketConnect () {
      // socket地址
      let socket = new SockJS("/cqparkApi/message-push/ws")
      // 可以手动在localStorage设置值开启debug模式
      let isDebug = localStorage.getItem("stomp-js-debug")
      // 创建客户端
      this.stompClient = webStomp.over(socket, {
        heartbeat: false,
        debug: isDebug ? isDebug : false,
        protocols: webStomp.VERSIONS.supportedProtocols()
      });
      // 连接
      this.stompClient.connect({}, frame => {
        console.log("连接成功", frame);
        if (this.stompClient) {
          // 订阅异常离场
          this.unusual = this.stompClient.subscribe(this.exceptionLeave, message => {
            console.log("消息:", message)
            this.unusualList.push(JSON.parse(message.body))
            //console.log(this.unusualList);
            this.unusualDialogVisible = true;
            if (this.unusualList.length > 50) {
              this.unusualList.splice(0, this.unusualList.length - 50)
              this.handleCurrentChange(this.unusualList.length)
            } else {
              this.handleCurrentChange(this.unusualList.length)
            }
          })
          // this.unusualDialogVisible = true;
          // this.testList.push({
          //   "parkName": "停车场",
          //   "plateColor": "黄色",
          //   "plateNumber": "苏BATM66",
          //   "leaveTime": "2023-03-23 01:23:22"
          // })
          // console.log(this.testList);
          // if (this.testList.length > 10) {
          //   this.testList.splice(0, this.testList.length - 10)
          //   this.handleCurrentChange(this.testList.length)
          // } else {
          //   this.handleCurrentChange(this.testList.length)
          // }
        } else {
          console.error("请先连接")

        }
      }, error => {
        console.error("连接出错", error)
        this.stompClient = null
      });
    },
    getDateUpdatePwd (startTime) {
      if (this.userCode != 'sysAdmin') {
        const end = new Date();//获取当前时间
        const start = new Date(startTime)
        //const start = new Date('2023-01-03 19:48:53')
        const result = (end - start) / (1000 * 60 * 60 * 24)
        if (result >= 90) {
          this.changePwdProup = true;
          this.cancelUpdatePwd = false;
          this.updatePassword()
        }
      }
    },


  },
};
</script>
<style lang='scss' scoped>
$headerHeight: 60px; // 头部高度
$asideWidth: 200px; // 侧边栏宽度
$sidebarWidth: 55px; // 侧边栏收缩后的宽度
.dt-layout {
  width: 100%;
  height: 100%;
  position: relative;
  // 头部样式
  .dt-layout-header {
    width: 100%;
    height: $headerHeight;
    display: flex;
    justify-content: space-between;
    .header-left {
      width: 271px;
      height: 146px;
      background-image: url('../../assets/layout/topleft.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 100;
      display: flex;
      pointer-events: none;
      img {
        width: 70px;
        height: 70px;
        margin-left: 50px;
        margin-top: 5px;
        pointer-events: auto;
      }
    }
    .header-left:hover {
      cursor: pointer;
    }
    .header-right {
      width: 100%;
      height: 99px;
      position: absolute;
      background-image: url('../../assets/layout/ul.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      line-height: 66px;
      color: #ffffff;
      .header-right-title {
        position: absolute;
        float: right;
        right: 140px;
      }
      .header-right-text {
        position: absolute;
        float: right;
        right: 0;
        .el-select {
          width: 140px;
          /deep/.el-input__inner {
            border: none !important;
            background-color: transparent !important;
            color: #ffffff !important;
            font-size: 16px !important;
            font-weight: normal !important;
          }
        }
      }
    }
  }
  .dt-layout-wrapper {
    width: 100%;
    height: calc(100% - #{$headerHeight});
    @include background('layoutContentBack');
    .dt-layout-aside {
      height: 100%;
      background: linear-gradient(
        270deg,
        rgba(22, 15, 0, 0.8) 0%,
        rgba(1, 1, 1, 1) 100%
      );
      .siderSelect {
        margin-top: 30px;
        pointer-events: auto;
      }
      /deep/.el-input__inner {
        border: none !important;
        background-color: transparent !important;
        color: #fdfdfd !important;
        font-size: 16px !important;
        font-weight: normal !important;
      }
    }
    .dt-layout-main {
      @include background('layoutContentBack');
      height: 100%;
      padding: 10px;
    }
    .unusualClass {
      width: 460px;
      height: 320px;
      border-radius: 0px;
      background: #0a2041;
      position: absolute;
      bottom: 5.89%;
      right: 1.8%;
      z-index: 1;
      .unusualClass_parkName {
        height: 42px;
        background: linear-gradient(
          180deg,
          rgba(4, 16, 33, 0) 0%,
          #004077 100%
        );
        border-radius: 0px;
        font-size: 18px;
        display: flex;
        align-items: center;
        .unusualClass_parkName_charact {
          height: 25px;
          font-size: 18px;
          font-family: PingFangSC-Medium;
          color: #00ebfb;
          margin-left: 2.83%;
        }
        .unusualClass_close {
          position: absolute;
          right: 1.6%;
          background: transparent;
          border: none;
          color: #00ebfb;
        }
      }
      .unusualClass_plateColor_yellow {
        width: 280px;
        height: 140px;
        background-image: url('../../assets/plateNumber/yellow.png');
        background-repeat: no-repeat;
        background-size: 100%;
        box-shadow: 0px 3px 6px rgba(138, 138, 138, 0.16);
        margin: 25px 0 0 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      .unusualClass_plateColor_blue {
        width: 280px;
        height: 140px;
        background-image: url('../../assets/plateNumber/blue.png');
        background-repeat: no-repeat;
        background-size: 100%;
        box-shadow: 0px 3px 6px rgba(138, 138, 138, 0.16);
        margin: 25px 0 0 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      .unusualClass_plateColor_green {
        width: 280px;
        height: 140px;
        background-image: url('../../assets/plateNumber/green.png');
        background-repeat: no-repeat;
        background-size: 100%;
        box-shadow: 0px 3px 6px rgba(138, 138, 138, 0.16);
        margin: 25px 0 0 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      .unusualClass_plateNumber_two {
        font-size: 39px;
        font-family: Source Han Sans CN;
        font-weight: 400;
      }
      .unusualClass_leaveTime {
        text-align: center;
        height: 37px;
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 37px;
        color: #298ee8;
        margin-top: 9px;
      }
      .page {
        height: 28px;
        margin-top: 20px;
        text-align: center;
      }
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.dt-form-width {
  width: 230px;
}
.hideSidebar {
  width: $sidebarWidth;
  transition: width 0.3s;
}
.openSidebar {
  width: $asideWidth;
  transition: width 0.3s;
}
.withSidebar {
  width: calc(100% - #{$asideWidth});
  transition: width 0.3s;
}
.withoutSidebar {
  width: calc(100% - 55px);
  transition: width 0.3s;
}
</style>
